import React, { Component, useEffect, useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { getData, postData } from "../Utils";
import { useHistory, useParams } from "react-router-dom";
import { ModelTypes } from "../Utils";
export default function PaymentModel() {
  const params = useParams();
  const history = useHistory();
  const [model, setModel] = useState({
    price_1: 0,
    price_2: 0,
    price_3: 0,
    price_relocation: 0,
    name: "",
    model_type: "hourly",
  });

  useEffect(() => {
    if (params.id) {
      getData(`payment_models/${params.id}`).then((model) => setModel(model));
    } else {
      console.log("new model");
    }
  }, []);

  const onChangeValue = (event) => {
    setModel({ ...model, [event.target.name]: event.target.value });
  };

  const save = () => {
    const data = params.id ? { ...model, id: params.id } : model;
    console.log(data);
    postData("payment_models", data).then((r) => history.push("/paymentModels"));
  };
  return (
    <>
      <h2>Edit Price model</h2>
      <Form>
        <FormGroup>
          <FormGroup>
            <Label>Name</Label>
            <Input type="text" name="name" value={model.name} onChange={onChangeValue} />
          </FormGroup>
          <Label>Type</Label>
          <Input type="select" name="model_type" value={model.model_type} onChange={onChangeValue}>
            {Object.entries(ModelTypes).map(([k, v]) => (
              <option key={k} value={k}>
                {v}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Relocation Price</Label>
          <Input type="number" name="price_relocation" value={model.price_relocation} onChange={onChangeValue} />
        </FormGroup>
        {model.model_type === "hourly" && (
          <div>
            <FormGroup>
              <Label>Starting price</Label>
              <Input type="number" name="price_1" value={model.price_1} onChange={onChangeValue} />
            </FormGroup>
            <FormGroup>
              <Label>1 hour price</Label>
              <Input type="number" name="price_2" value={model.price_2} onChange={onChangeValue} />
            </FormGroup>
            <FormGroup>
              <Label>24h price</Label>
              <Input type="number" name="price_3" value={model.price_3} onChange={onChangeValue} />
            </FormGroup>{" "}
          </div>
        )}
        {model.model_type == "daily" && (
          <div>
            <FormGroup>
              <Label>0-1 day price</Label>
              <Input type="number" name="price_1" value={model.price_1} onChange={onChangeValue} />
            </FormGroup>
            <FormGroup>
              <Label>2-3 day price</Label>
              <Input type="number" name="price_2" value={model.price_2} onChange={onChangeValue} />
            </FormGroup>
            <FormGroup>
              <Label>4-40 day price</Label>
              <Input type="number" name="price_3" value={model.price_3} onChange={onChangeValue} />
            </FormGroup>
          </div>
        )}
        <Button color="primary" onClick={() => save()}>
          Save
        </Button>
      </Form>
    </>
  );
}
